/**
 * Loading indicator (complements shimmer)
 */

import React from 'react';

export const Loading = ({ children }) => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" data-testid="loading-spinner" />
            {children && (
                <span className="text-muted" style={{ marginTop: '5px' }}>
                    {children}
                </span>
            )}
        </div>
    );
};
