import React from 'react';

const NavigationTab = ({isActive, label, icon, targetTab, onTabClick}) => {
    return (
        <li className="nav-item" role="presentation">
            <a className={`nav-link${isActive? ' active': ''}`} href="/" role="tab" onClick={(e) => {
                e.preventDefault();
                onTabClick(targetTab);
            }
            }>
                <div className="d-flex align-items-center">
                    <div className="tab-icon">
                        <img src={icon} alt={'Tab icon'} style={{maxWidth: '25px'}}/>
                    </div>
                    <div className="tab-title ms-1">{label}</div>
                </div>
            </a>
        </li>
    );
};

export default NavigationTab;