import React, {useState} from 'react';
import NavigationTab from "./navigation-tab";
import GraphQLIcon from './common/ui/assets/icons/graphql.svg';
import EmailIcon from './common/ui/assets/icons/email.svg';
import Graphql from "./graphql";
import Emails from "./emails/emails";

const Main = () => {
    const [activeTab, setActiveTab] = useState('graphql');

    return (
        <div className="card">
            <div className="card-body">
                <ul className="nav nav-tabs nav-success" role="tablist">
                    <NavigationTab isActive={activeTab === 'graphql'} label={'GraphQL'} icon={GraphQLIcon} targetTab={'graphql'} onTabClick={setActiveTab}/>
                    <NavigationTab isActive={activeTab === 'emails'} label={'Emails'} icon={EmailIcon} targetTab={'emails'} onTabClick={setActiveTab}/>
                </ul>
                <div className="tab-content py-3">
                    <div className="tab-pane fade active show" role="tabpanel">
                        {
                            activeTab === 'graphql' &&
                            <Graphql/>
                        }
                        {
                            activeTab === 'emails' &&
                            <Emails/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;