import React  from "react";
import "./main.css";
import Logo from '../../common/ui/assets/icons/icon.svg';
import BackgroundImg from "./login-bg.jpg";
import Error  from "../../common/ui/error";
import Shimmer  from "../../common/ui/shimmer";

const LoginForm = ({
  error,
  loading,
  signInContainer,
}) => {
  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <div className="login100-form validate-form">
            <span
              className="login100-form-title"
              style={{ paddingBottom: "43px", marginTop: "10vh" }}
            >
              <img
                src={Logo}
                alt={"Saudi Tracking"}
                style={{ maxWidth: "300px" }}
              />
            </span>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <div ref={signInContainer} />
              </div>
            </div>
            {loading && (
              <div className="mt-2">
                <Shimmer />
              </div>
            )}
            {!!error && (
              <div className="row mt-3">
                <Error>{error.message}</Error>
              </div>
            )}
          </div>
          <div
            className="login100-more"
            style={{ backgroundImage: `url(${BackgroundImg})` }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;