import React, {useState} from 'react';
import INTROSPECTION_GQL from './introspection.graphql';
import {useQuery} from "@apollo/client";
import {EXCEPTIONS_LIST} from "./exception-list";
import {activeGraphQLType} from "./type-explorer/cache";

const GraphQLList = () => {
    const {data, error, loading} = useQuery(INTROSPECTION_GQL);
    const [search, setSearch] = useState('');

    const allCallableTypes = [];
    if(data && data?.__schema?.types)
    {
        for(const entry of data.__schema.types)
        {
            if(entry.__typename === '__Type' && entry.kind === 'OBJECT' && ['Mutation', 'Query'].includes(entry.name))
            {
                for(const callableType of entry.fields)
                {
                    if(EXCEPTIONS_LIST.includes(callableType.name))
                    {
                        continue;
                    }
                    allCallableTypes.push({
                        type: entry.name,
                        name: callableType.name,
                        description: callableType.description
                    });
                }
            }
        }
    }
    //sort alphabetically, displays tags (M or Q + "Optimized")
    allCallableTypes.sort((e1, e2) => {
        if(e1.name < e2.name)
        {
            return -1;
        }
        if(e1.name > e2.name)
        {
            return 1;
        }
        return  0;
    });
    if(error)
    {
        alert('This is a fancy error alert letting you know that an error occurred! Let one of the BE devs know if this persists');
    }
    return (
        <>
            <input type="text" className="form-control" onChange={({target}) => setSearch(target.value)}
                   placeholder={loading? 'Working..': `Search ${allCallableTypes.length} entries`} autoFocus={true}/>
            <div className="list-group mt-2" style={{overflowY: 'auto', height: 'calc(80vh - 100px)'}}>
                {
                    allCallableTypes.filter(entry => search.trim() === '' || entry.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1).map((entry, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <a href="" className="list-group-item list-group-item-action" title={entry.description ?? ''} onClick={(e) => {
                                    activeGraphQLType({
                                        allTypes: data.__schema.types,
                                        activeCallableType: entry
                                    });
                                    e.preventDefault();
                                }}>
                                    <span className="badge me-1" style={{backgroundColor: entry.type === 'Mutation'? '#17a2b8': 'darkgray'}}>
                                        {entry.type === 'Mutation'? 'M': 'Q'}
                                    </span>
                                    {entry.name}
                                </a>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </>
    )
};

export default GraphQLList;