import React, {useEffect, useState} from 'react';
import {useReactiveVar} from "@apollo/client";
import {activeGraphQLType} from "./cache";

/**
 * Builds input & output fields
 */
const buildInputOutput = (data, targetCallableType) => {
    let inputFields = [];
    let outputFields = [];
    for(const entry of data)
    {
        if(entry.__typename === '__Type' && entry.kind === 'OBJECT' && entry.name === targetCallableType.type)
        {
            for(const callableType of entry.fields)
            {
                if(targetCallableType.name === callableType.name)
                {
                    for(const arg of callableType.args)
                    {
                        inputFields.push({
                            name: arg.name,
                            type: arg.type?.kind === 'LIST'? arg.type.ofType.ofType.name: arg.type.ofType === null? arg.type.name: arg.type.ofType.name,
                            description: arg.description,
                            isRequired: arg.type?.kind === 'NON_NULL',
                            isList: arg.type?.kind === 'LIST',
                            isListItemRequired: arg.type?.kind === 'LIST'? arg.type.ofType?.kind === 'NON_NULL': null,
                            isScalar: arg.type?.kind === 'SCALAR' || arg.type?.ofType?.kind === 'SCALAR' || arg.type?.ofType?.ofType?.ofType?.kind === 'SCALAR'
                        });
                    }
                    const isOutputAList = callableType.type?.ofType?.kind === 'LIST' || callableType.type?.kind === 'LIST';
                    outputFields.push({
                        name: callableType.type?.ofType?.name ?? callableType.type?.ofType?.ofType?.ofType?.name ?? callableType.type?.ofType?.ofType?.name,
                        type: callableType.type?.name ?? callableType.type?.ofType?.name ?? callableType.type?.ofType?.ofType?.name ?? callableType.type?.ofType?.ofType?.ofType?.name,
                        isRequired: callableType.type?.kind === 'NON_NULL',
                        isList: isOutputAList,
                        isListItemRequired: isOutputAList? callableType.type.ofType?.ofType?.kind === 'NON_NULL' || callableType.type?.ofType?.kind === 'NON_NULL': null,
                        isScalar: callableType.type?.ofType?.kind === 'SCALAR' || callableType.type?.ofType?.ofType?.kind === 'SCALAR' || callableType.type?.ofType?.ofType?.ofType?.kind === 'SCALAR'
                    });
                }
            }
        }
    }
    return {inputFields, outputFields};
};

const TypeExplorer = () => {
    const activeType = useReactiveVar(activeGraphQLType);
    const [inputFields, setInputFields] = useState(null);
    const [outputFields, setOutputFields] = useState(null);

    /**
     * Reset internal state when external selection changes
     */
    useEffect(() => {
        setInputFields(null);
    }, [activeType]);

    if(activeType === null)
    {
        return null;
    }
    if(inputFields === null || outputFields === null)
    {
        const {inputFields: _if, outputFields: _of} = buildInputOutput(activeType.allTypes, activeType.activeCallableType);
        setInputFields(_if);
        setOutputFields(_of);
        return null;
    }


    /**
     * Finds type's fields
     */
    const findTypeFields = (name, kind, indexes, isInput) => {
        for(const entry of activeType.allTypes)
        {
            if((entry.name === name || entry.name === kind) && ['INPUT_OBJECT', 'OBJECT', 'UNION', 'ENUM'].includes(entry.kind))
            {
                const fields = entry.inputFields ?? entry.fields ?? entry.possibleTypes ?? entry.enumValues;
                const children = [];
                for(const field of fields)
                {
                    const isList = field.type?.ofType?.kind === 'LIST' || field.type?.kind === 'LIST';
                    const record = {
                        name: field.name,
                        type: field.type?.name ?? field.type?.ofType?.name ?? field.type?.ofType?.ofType?.name ?? field.type?.ofType?.ofType?.ofType?.name,
                        description: field.description,
                        isRequired: field.type?.kind === 'NON_NULL',
                        isList: isList,
                        isListItemRequired: isList? field.type.ofType?.kind === 'NON_NULL' || field.type.ofType?.ofType?.kind === 'NON_NULL': null,
                        isScalar: field.type?.kind === 'SCALAR' || field.type?.ofType?.kind === 'SCALAR' || field.type?.ofType?.ofType?.kind === 'SCALAR' || field.type?.ofType?.ofType?.ofType?.kind === 'SCALAR'
                    };
                    if(entry.kind === 'UNION')
                    {
                        record.type = field.name;
                        record.description = 'Element of Union';
                    }
                    else if(entry.kind === 'ENUM')
                    {
                        record.type = 'String';
                        record.isScalar = true;
                        record.description = 'Value of Enum'
                    }
                    children.push(record);
                }
                const state = isInput? [...inputFields]: [...outputFields];
                let currentEntry = null;
                for(const idx of indexes)
                {
                    currentEntry = currentEntry === null? state[idx]: currentEntry.children[idx];
                    if(!currentEntry.children)
                    {
                        currentEntry.children = children;
                    }
                }
                if(isInput)
                {
                    setInputFields(state);
                }
                else {
                    setOutputFields(state);
                }
            }
        }
    };

    /**
     * Returns markup of input or output fields
     */
    const getEntryHtmlDisplay = (entry, indexes, isInput) => {
        const targetKey = isInput || indexes.length > 0? 'type': 'name';
        let type = entry.isList? `[${entry[targetKey]}${entry.isListItemRequired? '!': ''}]`: `${entry[targetKey]}`;
        type = `${type}${entry.isRequired? '!': ''}`;

        if(!entry.isScalar)
        {
            type = <a href={'/'} style={{textDecoration: 'underline'}} onClick={(e) => {
                e.preventDefault();
                findTypeFields(entry.name, entry.type, indexes, isInput);
            }
            }>{type}</a>
        }
        return type;
    };

    /**
     * Renders nested tree
     */
    const renderTree = (entries, indexes=[], isInput=true) => {
        return entries.map((entry, idx) => {
            return (
                <li key={`input-${entry.name}-${entry.type}`}>
                    {
                       !!(isInput || indexes.length > 0) &&
                        <span>{entry.name}: </span>
                    }
                    {getEntryHtmlDisplay(entry, [...indexes, idx], isInput)}
                    {
                        entry.description !== null &&
                        <div className="text-muted"><small style={{whiteSpace: 'pre-line'}}>{entry.description}</small></div>
                    }
                    {
                        !!entry?.children?.length &&
                        <>
                            <ul>
                                {renderTree(entry.children, [...indexes, idx], isInput)}
                            </ul>
                        </>
                    }
                </li>
            )
        });
    };

    return (
        <>
            <div className="card">
                <div className="card-header">
                    {activeType.activeCallableType.type}: <b>{activeType.activeCallableType.name}</b>
                </div>
                <div className="card-body">
                    {
                        !!activeType.activeCallableType.description &&
                        <span className="text-muted"><small style={{whiteSpace: 'pre-line'}}>{activeType.activeCallableType.description}</small></span>
                    }
                    {
                        inputFields.length > 0 &&
                        <>
                            <div className="mt-3">
                                <h6>Input:</h6>
                                <ul>
                                    {
                                        renderTree(inputFields)
                                    }
                                </ul>
                            </div>
                        </>
                    }
                    {
                        <>
                            <div className="mt-3">
                                <h6>Output:</h6>
                                {
                                    <ul>
                                        {renderTree(outputFields, [], false)}
                                    </ul>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    );
};

export default TypeExplorer;