import React from 'react';
import GraphQLList from "./list";
import MockSwitch from "./mock-switch";
import TypeExplorer from "./type-explorer";
import MockScenarios from "./scenarios";
import {useReactiveVar} from "@apollo/client";
import {activeGraphQLType} from "./type-explorer/cache";

const Graphql = () => {
    const activeType = useReactiveVar(activeGraphQLType);

    return (
        <div className="row">
            <div className="col-3">
                <GraphQLList/>
            </div>
            <div className="col-7">
                <MockSwitch/>
                <TypeExplorer/>
                {
                    activeType !== null &&
                    <MockScenarios key={activeType.activeCallableType.name}/>
                }
            </div>
        </div>
    );
};

export default Graphql;