/**
 * Shimmer effect, https://codepen.io/dhilipkmr/pen/xejEBa
 */

import './style.css';

const Shimmer = () => {
    return (
        <div className="shimmer-wrapper">
            <div className="shimmer-comment shimmer-br shimmer-animate shimmer-w80"/>
            <div className="shimmer-comment shimmer-br shimmer-animate shimmer-w80"/>
            <div className="shimmer-comment shimmer-br shimmer-animate shimmer-w80"/>
        </div>
    );
};

export default Shimmer;