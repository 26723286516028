import React, {Suspense} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./header";
import Footer from "./footer";
import Main from './main';

function App() {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Suspense fallback={<span/>}>
                <BrowserRouter>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<Main/>}/>
                    </Routes>
                    <Footer/>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
