import React, {useEffect, useRef, useState} from 'react';
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {activeGraphQLType} from "../type-explorer/cache";
import PlusIcon from '../../common/ui/assets/icons/plus.svg';
import SET_SCENARIOS_GQL from "./set-scenarios.graphql";
import SCENARIOS_GQL from './scenarios.graphql';
import EditorJS from 'jsoneditor';
import Error from "../../common/ui/error";
import 'jsoneditor/dist/jsoneditor.css';

const defaultScenario = {
    isEnabled: true,
    title: '',
    delayInSeconds: 0,
    responseWithError: '',
    errorMessage: ''
};

const jsonEditorDefaultOptions = {
    mode: 'code',
    modes: ['code', 'form', 'text', 'tree', 'view']
};

const MockScenarios = () => {
    const activeType = useReactiveVar(activeGraphQLType);
    const [scenarios, setScenarios] = useState(null);
    const [activeTabIdx, setActiveTabIdx] = useState(null);
    const [persistScenarios, {
        loading: persistScenariosLoading,
        error: persistScenariosError
    }] = useMutation(SET_SCENARIOS_GQL);
    const {data: scenariosData, error: scenariosError, loading: scenariosLoading} = useQuery(SCENARIOS_GQL, {variables: {callableName: activeType.activeCallableType.name}});
    //active JSON input container & editor
    const inputContainer = useRef(null);
    const inputEditor = useRef(null);
    //Input keyed by activeTabIdx
    const inputJSONValues = useRef({});
    //active JSON output container & editor
    const outputContainer = useRef(null);
    const outputEditor = useRef(null);
    //Output keyed by activeTabIdx
    const outputJSONValues = useRef({});

    /**
     * When switching between scenarios, JSON may be invalid
     */
    const parseDirtyJSON = (container, idx) => {
        try {
            return JSON.parse(container.current[idx]);
        }
        catch (err)
        {
            const oldText = container.current[idx];
            navigator.clipboard.writeText(oldText).then(function() {
                alert('Your JSON in this scenario is invalid. Text is copied to the clipboard!');
            });
            container.current[idx] = '{}';
            return {};
        }
    };

    /**
     * Initialize JSON editor for input
     */
    useEffect(() => {
        if(inputContainer.current === null || activeTabIdx === null)
        {
            return;
        }
        if(inputEditor.current !== null)
        {
            inputEditor.current.destroy();
        }
        const editorOptions = {
            ...jsonEditorDefaultOptions,
            onChange: () => {
                inputJSONValues.current[activeTabIdx] = inputEditor.current.getText();
            }
        };
        inputEditor.current = new EditorJS(inputContainer.current, editorOptions);
        inputEditor.current.set(!inputJSONValues.current[activeTabIdx]? {}: parseDirtyJSON(inputJSONValues, activeTabIdx));
        return () => {
            inputEditor.current.destroy();
            inputEditor.current = null;
        };
    }, [activeTabIdx]);

    /**
     * Initialize JSON editor for output
     */
    useEffect(() => {
        if(outputContainer.current === null || activeTabIdx === null)
        {
            return;
        }
        if(outputEditor.current !== null)
        {
            outputEditor.current.destroy();
        }
        const editorOptions = {
            ...jsonEditorDefaultOptions,
            onChange: () => {
                outputJSONValues.current[activeTabIdx] = outputEditor.current.getText();
            }
        };
        outputEditor.current = new EditorJS(outputContainer.current, editorOptions);
        outputEditor.current.set(!outputJSONValues.current[activeTabIdx]? {response: ''}: parseDirtyJSON(outputJSONValues, activeTabIdx));
        return () => {
            outputEditor.current.destroy();
            outputEditor.current = null;
        };
    }, [activeTabIdx]);


    if(!scenariosLoading && !scenariosError && scenarios === null)
    {
        if(!scenariosData?.result)
        {
            //no mocks
            setScenarios([defaultScenario]);
        }
        else {
            const persistedScenarios = JSON.parse(scenariosData.result);
            let scenarioIdx = 0;
            for(const entry of persistedScenarios)
            {
                inputJSONValues.current[scenarioIdx] = entry.input;
                outputJSONValues.current[scenarioIdx] = entry.output;
                scenarioIdx++;
            }
            setScenarios(persistedScenarios);
        }
        setActiveTabIdx(0);
    }
    if(scenarios === null || !scenarios[activeTabIdx] || activeTabIdx === null)
    {
        return null;
    }

    /**
     * On field change
     */
    const onFieldChange = ({target}) => {
        const values = [...scenarios];
        const existingValue = values[activeTabIdx][target.id];
        values[activeTabIdx] = {...values[activeTabIdx], [target.id]: target.value};
        if (target.id === 'isEnabled') {
            values[activeTabIdx].isEnabled = !existingValue;
        } else if (target.id === 'delayInSeconds' && !/^\d+$/.test(target.value)) {
            values[activeTabIdx].delayInSeconds = '';
        }
        setScenarios(values);
    };

    /**
     * On submit
     */
    const onSubmit = async () => {
        let idx = 0;
        const normalizedInputValues = [];
        const normalizedOutputValues = [];
        for (const scenario of scenarios) {
            const inputErrorMessage = `Input in ${scenario.title.trim() !== '' ? scenario.title : `Scenario ${idx + 1}`} is not valid!`;
            const outputErrorMessage = `Output in ${scenario.title.trim() !== '' ? scenario.title : `Scenario ${idx + 1}`} is not valid!`;
            const inputValue = (inputJSONValues.current[idx] ?? '{}').trim();
            const outputValue = (outputJSONValues.current[idx] ?? JSON.stringify({response: ''})).trim();
            try {
                JSON.parse(inputValue);
            } catch (err) {
                alert(inputErrorMessage);
                return;
            }
            if (!inputValue.startsWith('{')) {
                alert(inputErrorMessage);
                return;
            }
            let parsedOutput;
            try {
                parsedOutput = JSON.parse(outputValue);
            }
            catch (err)
            {
                alert(outputErrorMessage);
                return;
            }
            if(typeof parsedOutput?.response === 'undefined' || Object.keys(parsedOutput).length > 1)
            {
                alert(outputErrorMessage);
                return;
            }
            idx += 1;
            normalizedInputValues.push(inputValue);
            normalizedOutputValues.push(outputValue);
        }
        try {
            await persistScenarios({
                variables: {
                    callableName: activeType.activeCallableType.name,
                    scenarios: JSON.stringify(
                        scenarios.map((r, idx) => ({
                            ...r,
                            title: r.title.trim(),
                            input: normalizedInputValues[idx],
                            output: normalizedOutputValues[idx]
                        }))
                    )
                }
            });
            alert('Saved!');
        } catch (err) {
            alert('Something is not right!');
        }
    };

    /**
     * Submit button
     */
    const SubmitButton = () => {
        return (
            <button className="btn btn-primary" onClick={onSubmit} style={{backgroundColor: '#014157'}} disabled={persistScenariosLoading}>
                Save changes
            </button>
        )
    };

    if(scenariosError)
    {
        return <Error>{scenariosError}</Error>
    }

    return (
        <>
            <div className="card mt-3">
                <div className="card-header">
                    Mock Scenarios for <b>{activeType.activeCallableType.name}</b>
                </div>
                <div className="card-body">
                    <ul className="nav nav-tabs">
                        {
                            scenarios.map((entry, idx) => {
                                return (
                                    <li className="nav-item" key={`scenario-tab-${idx}`}>
                                        <button onClick={() => {
                                            setActiveTabIdx(idx);
                                        }
                                        } className={`btn-link nav-link${activeTabIdx === idx ? ' active' : ''}`}>
                                            {entry.title === '' ? `Scenario ${idx + 1}` : entry.title}
                                        </button>
                                    </li>
                                )
                            })
                        }
                        <li className="nav-item">
                            <button title={'Add more'} onClick={() => {
                                const newScenarios = [...scenarios, defaultScenario];
                                let newIdx = newScenarios.length - 1;
                                setActiveTabIdx(newIdx);
                                setScenarios(newScenarios);
                            }} className={`btn-link nav-link`}>
                                <img src={PlusIcon} alt={'Add more'} style={{width: '15px'}}/>
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" style={{marginTop: '30px'}}>
                        <div className="tab-pane fade show active">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col col-12 col-md-4 mt-4 mt-sm-0 text-left">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch"
                                                   id={`isEnabled`} checked={scenarios[activeTabIdx].isEnabled}
                                                   onChange={onFieldChange}/>
                                            <label className="form-check-label" htmlFor={`isEnabled`}>
                                                Enabled
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 mt-4 mt-sm-0">
                                        <SubmitButton/>
                                    </div>
                                    <div className="col col-12 col-md-4 mt-4 mt-sm-0" style={{textAlign: 'right'}}>
                                        {
                                            scenarios.length > 1 &&
                                            <button className="btn btn-danger" onClick={() => {
                                                const updatedScenarios = [...scenarios];
                                                updatedScenarios.splice(activeTabIdx, 1);
                                                setScenarios(updatedScenarios);
                                                const newTabIdx = activeTabIdx - 1;
                                                setActiveTabIdx(newTabIdx < 0 ? 0 : newTabIdx);
                                            }
                                            }>Delete scenario</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col col-12 col-md-4 mt-4 mt-sm-0 text-left">
                                        <label htmlFor="title">Title</label>
                                        <input id="title" type="text" value={scenarios[activeTabIdx].title}
                                               placeholder={'Optional'} className={'form-control'}
                                               onChange={onFieldChange}/>
                                    </div>
                                    <div className="col col-12 col-md-4 mt-4 mt-sm-0 text-left">
                                        <label htmlFor="delayInSeconds">Delay in seconds</label>
                                        <input id="delayInSeconds" type="text"
                                               value={scenarios[activeTabIdx].delayInSeconds} className={'form-control'}
                                               onChange={onFieldChange}/>
                                    </div>
                                    <div className="col col-12 col-md-4 mt-4 mt-sm-0 text-left">
                                        <label htmlFor="responseWithError">Respond with an error</label>
                                        <select className={'form-control'}
                                                id="responseWithError" value={scenarios[activeTabIdx].responseWithError}
                                                onChange={onFieldChange}>
                                            <option value={''}>No error</option>
                                            <option value={'VALIDATION_ERROR'}>VALIDATION_ERROR</option>
                                            <option value={'INTERNAL_SERVER_ERROR'}>INTERNAL_SERVER_ERROR</option>
                                            <option
                                                value={'INVALID_AUTHENTICATION_ERROR'}>INVALID_AUTHENTICATION_ERROR
                                            </option>
                                            <option value={'REDUNDANCY_ERROR'}>REDUNDANCY_ERROR</option>
                                            <option value={'UNAUTHORIZED_ACCESS_ERROR'}>UNAUTHORIZED_ACCESS_ERROR
                                            </option>
                                            <option value={'UNIMPLEMENTED_FEATURE_ERROR'}>UNIMPLEMENTED_FEATURE_ERROR
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                scenarios[activeTabIdx].responseWithError !== '' &&
                                <div className="form-group mt-3">
                                    <div className="row">
                                        <div className="col col-12 col-md-4 mt-4 mt-sm-0 text-left">
                                            <label htmlFor="errorMessage">Error message</label>
                                            <input id="errorMessage" type="text"
                                                   value={scenarios[activeTabIdx].errorMessage} placeholder={'Optional'}
                                                   className={'form-control'} onChange={onFieldChange}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            <hr/>
                            <div className="form-group mt-3">
                                <div className="row">
                                    <div className="col col-12 mt-4 mt-sm-0 text-left">
                                        <label>Input</label>
                                        <div style={{height: '500px'}} ref={elem => inputContainer.current = elem}/>
                                        <small className="text-muted">
                                            Enter valid JSON that is compatible with the declared input types (if any).
                                            <br/><br/>
                                            This gets matched with React GraphQL requests and the response below this is returned when a match is detected.
                                            <br/><br/>
                                            Leave this blank <code>{JSON.stringify({})}</code> to match all requests.
                                            <br/><br/>
                                            If set, this needs to exactly match incoming requests!
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="form-group mt-4">
                                <div className="row">
                                    <div className="col col-12 mt-4 mt-sm-0 text-left">
                                        <label>Output</label>
                                        <div style={{height: '500px'}} ref={elem => outputContainer.current = elem}/>
                                        <small className="text-muted">
                                            Output must always be within the <code>"response"</code> root key.
                                            <br/><br/>
                                            Within that, enter a response that is compatible with the return type, examples:
                                            <br/><br/>
                                            If the mutation or query returns `Int!`, output is <code>{JSON.stringify({response: 1})}</code>
                                            <br/><br/>
                                            <code>{JSON.stringify({response: true})}</code>
                                            <br/><br/>
                                            <code>{JSON.stringify({response: null})}</code>
                                            <br/><br/>
                                            <code>{JSON.stringify({response: "abc"})}</code> for ID or String
                                            <br/><br/>
                                            <code>{JSON.stringify({response: {name: 'Mockery', age: 11}})}</code> for a custom object.
                                            <br/><br/>
                                            <code>{JSON.stringify({response: [{name: 'Mockery', age: 11}]})}</code> for array of custom objects, etc.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="form-group mt-3">
                                <div className="row">
                                    <div className="col col-10" style={{textAlign: 'center'}}>
                                        <SubmitButton/>
                                    </div>
                                </div>
                            </div>
                            {
                                persistScenariosError &&
                                <>
                                    <hr/>
                                    <Error>{persistScenariosError}</Error>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MockScenarios;