/**
 * Turns mocks on/off.
 * When off, disables all existing mocks.
 * When on, allows existing mocks to intercept requests
 */

import React, {useState} from 'react';
import STATE_GQL from './state.graphql';
import SET_STATE_GQL from './set-state.graphql';
import {useMutation, useQuery} from "@apollo/client";
import {Loading} from "../../common/ui/loading";
import Error from "../../common/ui/error";
import InfoIcon from '../../common/ui/assets/icons/info.svg';

const MockSwitch = () => {
    const {data, error, loading} = useQuery(STATE_GQL);
    const [setPersistedState, {loading: setPersistedStateLoading, error: setPersistedStateError }] = useMutation(SET_STATE_GQL);
    const [mockState, setMockState] = useState(null);

    if(data?.result !== undefined && mockState === null)
    {
        setMockState(data.result);
    }

    /**
     * Sets switch's state
     */
    const onStateChange = async () => {
        const newState = !mockState;
        setMockState(newState);
        await setPersistedState({variables: {state: newState}});
    };

    return (
        <>
            <div className="form-check form-switch">
                {
                    !!(loading || setPersistedStateLoading) &&
                    <Loading/>
                }
                {
                    !loading && mockState !== null && !error &&
                    <input className="form-check-input" type="checkbox" role="switch" id="mock-state" checked={mockState} onChange={onStateChange}/>
                }
                {
                    !error && !setPersistedStateError &&
                    <label className="form-check-label" htmlFor="mock-state">
                        My mocks are enabled
                        <img src={InfoIcon} alt={'Info'} title={'Lets you quickly turn your existing mocks on & off'} style={{width: '30px'}}/>
                    </label>
                }
                {
                    !!(error || setPersistedStateError) &&
                    <Error>Something is not right!</Error>
                }
            </div>
            <hr/>
        </>
    );
};

export default MockSwitch;