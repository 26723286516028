/**
 * List of queries / mutations to be excluded from the list
 */

export const EXCEPTIONS_LIST = [
    'feGraphQLMockToolsSwitchState',
    'setFEGraphQLMockToolsSwitchState',
    'setFEGraphQLMockToolsScenarios',
    'feGraphQLMockToolsScenarios',
    'feWhiteListedEmailsAndRedirects',
    'setFEEmailWhiteListAndRedirects'
];