import React from 'react';
import ReactDOM from 'react-dom/client';
import {RetryLink} from '@apollo/client/link/retry';
import {InMemoryCache} from '@apollo/client';
import {ApolloClient, ApolloProvider, ApolloLink} from '@apollo/client';
import {createUploadLink} from 'apollo-upload-client';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import AuthController from "./auth-controller";

/**
 * Initialize Apollo client
 */
const linkOptions = {
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URI,
    fetch: (uri, options) => {
        const token = localStorage.getItem('token');
        if (token) {
            options.headers['auth-token'] = token;
        }
        return fetch(uri, options);
    }
};

const retryLink = new RetryLink({
    attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error,
        delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
            factor: 2
        }
    }
});

const apolloMiddleware = new ApolloLink((operation, forward)=>{
    return forward(operation);
});

const link = ApolloLink.from([retryLink, apolloMiddleware, createUploadLink(linkOptions)]);

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
      <AuthController>
          <App />
      </AuthController>
  </ApolloProvider>
);

reportWebVitals();
