import React from 'react';
import {Link} from 'react-router-dom';
import Icon from './common/ui/assets/icons/icon.svg';
import {Helmet} from "react-helmet";

const Header = () => {
    return (
        <>
            <Helmet>
                <title>Mockery!</title>
            </Helmet>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light main-bg-color">
                    <div className="container" style={{marginLeft: '30px'}}>
                        <Link className="navbar-brand" to="/" style={{color: 'white'}}>
                            <img src={Icon} alt={'Icon'} className="mb-1" style={{width: '40px', verticalAlign: 'middle'}}/>
                            <span className="ms-2">Mockery!</span>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">

                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};


export default Header;