import React, {useEffect, useRef} from 'react';
import {useMutation, useQuery} from "@apollo/client";
import WHITELISTED_EMAILS_GQL from './whitelisted-emails.graphql';
import SET_WHITELISTED_EMAILS_GQL from './set-whitelist.graphql';
import Shimmer from "../common/ui/shimmer";
import Error from "../common/ui/error";
import EditorJS from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

const Emails = () => {
    const {data, error, loading} = useQuery(WHITELISTED_EMAILS_GQL);
    const [setWhiteListedEmails, {loading: mutationLoading}] = useMutation(SET_WHITELISTED_EMAILS_GQL);
    const jsonContainer = useRef(null);
    const jsonEditor = useRef(null);
    const inputJSONValue = useRef(data?.result ?? {});

    /**
     * Initialize JSON editor
     */
    useEffect(() => {
        if(jsonContainer.current === null || !data?.result)
        {
            return;
        }
        if(jsonEditor.current !== null)
        {
            jsonEditor.current.destroy();
        }
        const editorOptions = {
            mode: 'code',
            modes: ['code', 'form', 'text', 'tree', 'view'],
            onChange: () => {
                inputJSONValue.current = jsonEditor.current.getText();
            }
        };
        jsonEditor.current = new EditorJS(jsonContainer.current, editorOptions);
        jsonEditor.current.set(JSON.parse(data.result));
        inputJSONValue.current = data.result;
        return () => {
            jsonEditor.current.destroy();
            jsonEditor.current = null;
        };
    }, [data]);

    /**
     * Validates email address
     */
    const isValidEmail = (email) => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
    };

    /**
     * On save
     */
    const onSave = async () => {
        const jsonString = inputJSONValue.current;
        let jsonParsed;
        let error = null;
        try {
            jsonParsed = JSON.parse(jsonString);
            const whiteListedEmails = jsonParsed?.whiteListedEmails;
            const redirects = jsonParsed?.redirects;
            if(!Array.isArray(whiteListedEmails) || !(typeof redirects === 'object' && redirects !== null && !Array.isArray(redirects) && Object.prototype.toString.call(redirects) === '[object Object]'))
            {
                error = `Invalid type of "whiteListedEmails" or "redirects", expecting "[]" & "{}", respectively`;
            }
            if(!error)
            {
                for(const entry of whiteListedEmails)
                {
                    if(typeof entry !== 'string' || !isValidEmail(entry))
                    {
                        error = `Expecting elements in "whiteListedEmails" to be valid emails!`;
                        break;
                    }
                }
            }
            if(!error)
            {
                for(const targetEmail in redirects)
                {
                    if(Object.prototype.hasOwnProperty.call(redirects, targetEmail))
                    {
                        if(!isValidEmail(targetEmail))
                        {
                            error = `The redirect email "${targetEmail}" is invalid!`;
                            break;
                        }
                        if(typeof redirects[targetEmail] !== 'string')
                        {
                            error = `Expecting the value of "${targetEmail}" to be a comma separated list of emails!`;
                            break;
                        }
                        for(const entry of redirects[targetEmail].split(','))
                        {
                            if(!isValidEmail(entry))
                            {
                                error = `The email "${entry}" under "${targetEmail}" is invalid!`;
                                break;
                            }
                        }
                    }
                }
            }
        }
        catch (_)
        {
            error = `Error parsing JSON!`;
        }
        if(error !== null)
        {
            alert(error);
            return;
        }
        try {
            await setWhiteListedEmails({
                variables: {
                    whiteListedEmails: jsonParsed.whiteListedEmails,
                    redirects: JSON.stringify(jsonParsed.redirects)
                }
            });
            alert('Saved!');
        }
        catch (err)
        {
            alert('Something is not right!');
        }
    };

    if(loading)
    {
        return <Shimmer/>
    }
    if(error)
    {
        return <Error>{error}</Error>
    }
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div style={{height: '500px'}} ref={elem => jsonContainer.current = elem}/>
                    <button className="btn btn-primary mt-3" onClick={onSave} style={{backgroundColor: '#014157'}} disabled={mutationLoading}>
                        Save
                    </button>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <small className="text-muted">
                        Whitelisted emails are allowed to receive notifications in development & staging.
                        <br/><br/>
                        Email redirects alter the actual recipient(s) when delivery is attempted, and can be used to test emails that are usually sent to external, non-developer email addresses.
                        <br/><br/>
                        For example, if <code>reservations@stellastays.com</code> should receive an email when an event occurs, you can set a redirect from this
                        email to <code>developer1@domain.com,developer2@domain.com</code>, which allows both developers to get the email instead of <code>reservations@stellastays.com</code>.
                        <br/><br/>
                        Multiple redirects may be set, and target emails defined in redirects (i.e.: <code>developer1@domain.com,developer2@domain.com</code>) don't need to be defined under <code>whiteListedEmails</code>.
                        <br/><br/>
                        Example:
                        <br/>
                        <code>
                            {
                                JSON.stringify({
                                    "whiteListedEmails": [
                                        "email1@domain.com",
                                        "email2@domain.com"
                                    ],
                                    "redirects": {
                                        "reservations@stellastays.com": "developer1@domain.com,developer2@domain.com",
                                        "otherEmailToBeIntercepted@domain.com": "developer3@domain.com,developer1@domain.com"
                                    }
                                }, null, 2)
                            }
                        </code>
                        <br/><br/>
                        Here, <code>email1@domain.com</code> receives any emails directly sent to it, while <code>developer1@domain.com</code> and <code>developer2@domain.com</code> receive any emails sent to <code>reservations@stellastays.com</code> but <code>developer1@domain.com</code> won't receive any emails sent to it directly!
                    </small>
                </div>
            </div>
        </>
    );
};

export default Emails;