import React from 'react';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-3 main-bg-color">
            <div className="container"/>
        </footer>
    )
};

export default Footer;