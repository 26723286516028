/**
 * Displays error alert
 */

import React, { useState } from 'react';

const Error = ({ children, withFrown = true, onHideError = () => {}, alertClass = 'alert-danger' }) => {
    const [isVisible, setIsVisible] = useState(true);
    /**
     * On close
     */
    const hideError = () => {
        setIsVisible(false);
        onHideError();
    };
    return (
        <>
            {isVisible && (
                <div className="row">
                    <div className="col">
                        <div className={`alert ${alertClass} alert-dismissible`} role="alert">
                            {children}
                            {withFrown && <span className="fa fa-frown-o ml-1" />}
                            <button
                                type="button"
                                className="close"
                                style={{ border: 'none', backgroundColor: alertClass === 'alert-danger' ? '#fae1e4' : '#fff3cd' }}
                                data-dismiss="alert"
                                aria-label="Close"
                                onClick={hideError}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Error;