/**
 * Auth controller
 */

import React, {useCallback, useEffect, useRef} from 'react';
import {useMutation, useQuery, useReactiveVar} from "@apollo/client";
import {accountVar} from "./cache";
import ME_GQL from './me.graphql';
import SIGN_IN_WITH_GOOGLE_GQL from './sign-in-with-google.graphql';
import {Helmet} from "react-helmet";
import Shimmer from "../common/ui/shimmer/index";
import LoginForm from "./login-form";

const AuthController = ({children}) => {
    const accountVarState = useReactiveVar(accountVar);
    const hasJWTToken = !!localStorage.getItem('token');
    const signInContainer = useRef(null);
    const { data: signInData, loading: signInLoading, error: signInError } = useQuery(ME_GQL, { skip: !hasJWTToken });
    const [signInWithGoogle, { data: signInMutationData, loading: signInMutationLoading, error: signInMutationError }] = useMutation(SIGN_IN_WITH_GOOGLE_GQL);

    /**
     * Loads external scripts synchronously
     */
    const loadExternalScript = (url, onLoad) => {
        const script = document.createElement('script');
        script.setAttribute('src', url);
        document.body.appendChild(script);
        script.onload = onLoad;
    };

    /**
     * Init sign-in flow
     */
    const initGoogleSignInFlow = useCallback(() => {
        loadExternalScript('https://accounts.google.com/gsi/client', () => {
            window.google.accounts.id.initialize({
                // eslint-disable-next-line camelcase
                client_id: process.env.REACT_APP_GOOGLE_IDENTITY_CLIENT_KEY,
                callback: async (response) => {
                    const signInVariables = {
                        googleToken: response.credential,
                        authRequester: 'user',
                    };
                    try {
                        await signInWithGoogle({ variables: signInVariables });
                    }
                    catch (err) {} // gets reported through signInMutationError
                },
            });
            window.google.accounts.id.renderButton(signInContainer.current, { type: 'standard', theme: 'outline', size: 'large' });
            // also display the One Tap dialog
            window.google.accounts.id.prompt();
        });
    }, [signInWithGoogle]);

    /**
     * Initialize sign-in
     */
    useEffect(() => {
        if (signInLoading) {
            return;
        }
        if (!signInError && !!signInData) {
            accountVar(signInData.result);
            return;
        }
        initGoogleSignInFlow();
    }, [signInData, signInLoading, signInError, initGoogleSignInFlow]);

    /**
     * On sign-in
     */
    useEffect(() => {
        if (!signInMutationLoading && !signInMutationError && !!signInMutationData) {
            localStorage.setItem('token', (signInMutationData.result).jsonWebToken);
            accountVar(signInMutationData.result);
        }
    }, [signInMutationData, signInMutationLoading, signInMutationError]);

    return (
        <>
            <Helmet>
                <title>Mockery - Sign In</title>
            </Helmet>
            {signInLoading && (
                <div style={{ paddingTop: '15%' }}>
                    <Shimmer />
                </div>
            )}
            {!signInLoading && accountVarState === null && (
                <LoginForm error={signInMutationError} loading={signInMutationLoading} signInContainer={signInContainer} />
            )}
            {!signInLoading && !!accountVarState && children}
        </>
    );


};

export default AuthController;